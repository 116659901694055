import pageClass from '@/unit/pageClass'

class searchFrom extends pageClass {
  constructor(type) {
    super()
    if (type === 'form') {
      // this.XDSJ = ''
      // this.KHSJH = ''
      // this.ZT = ''
      // this.DDZT = ''
      this.searchStatusType = ''
      this.finalVenueId = ''
      this.orderStTime = ''
      this.orderEndTime = ''
      this.orderStatus = ''
      this.orderStatusText = '全部'
      this.orderType = '0'
      this.searchCon = ''
      this.sportsProjectCode = ''
      this.orderFrom = ''
      this.payType = ''
    }
  }
}
export default searchFrom
