<template>
  <div class="dialog-container">
    <el-dialog
      title="添加备注"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="50%">
      <el-form ref="form" :model="form" label-width="0px" :rules="rules">
        <el-row>
          <el-col :span="24">
            <el-form-item prop="remark">
              <el-input clearable
                type="textarea"
                :rows="10"
                :maxlength="100"
                placeholder="请输入该订单的备注信息（商家备注）"
                v-model="form.remark">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template slot="footer">
        <el-button class="footer-confirm" @click="sureEvent">{{$t('sure')}}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import mixin from '@/mixins/dialogMixin'
// import AddDialogClass from './addDialogClass'
import RemarkDialogClass from './remarkDialogClass'
import apis from '@/apis'

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
    id: {
      required: true,
      type: String,
      default: '',
    },
  },
  data() {
    return {
      form: new RemarkDialogClass('form'), // 实例化一个表单的变量
      rules: new RemarkDialogClass('rule', this.Language), // 实例化一个表单的规则
      textarea: '',
    }
  },
  computed: {
    ...mapState(['DROPDOWNBOX']),
  },
  methods: {
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields()
      console.log('我初始化了')
    },
    openDialog() {
      this.$http.post(apis.orderDetails, { id: this.id }).then((res) => {
        if (res.data.code === 0) {
          this.form.remark = res.data.rows.businessRemark
        }
      })
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(data, type) {
      let remark
      this.id = data.id
      if (type) {
        remark = 1
      } else {
        remark = 2
      }
      const info = {
        id: data.id,
        remark,
      }
      // 在这里获取回显的数据
      this.$http.post(apis.orderDetails, info).then((res) => {
        if (res.data.code === 0) {
          // this.data = res.data.rows
          this.form.remarkInfo = res.data.rows.businessRemark
        }
      })
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.id = this.id
          this.$http.post(apis.updateRemark, this.form).then((res) => {
              if (res.data.code === 0) {
                this.closeDialog()
              }
            })
        }
      })
    },
  },
}
</script>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
</style>
